import request from '@/api/request.js'

/**
 * @Description: 跑腿规则
 */

// 分页查询
export function pageWmsCarrierRule(data) {
    return request({
        url: '/api/wmsCarrierRule/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsCarrierRule(data) {
    return request({
        url: '/api/wmsCarrierRule/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsCarrierRule(data) {
    return request({
        url: '/api/wmsCarrierRule/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsCarrierRule(data) {
    return request({
        url: '/api/wmsCarrierRule/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsCarrierRule(data) {
    return request({
        url: '/api/wmsCarrierRule/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsCarrierRule(data) {
    return request({
        url: '/api/wmsCarrierRule/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsCarrierRule(data) {
    return request({
        url: '/api/wmsCarrierRule/batchDelete',
        method: 'POST',
        data
    })
}

