
<template>
  <div>
    <el-dialog v-bind="$attrs" v-on="$listeners"  @close="onClose"
    title="编辑跑腿规则"
    :visible.sync="visible"
    width="600px"
    >

      <el-form ref="form" :model="row" :rules="rules" size="mini" label-width="120px"      >
            <el-row>

        <el-col :span="24">
                <el-form-item label="规则名称" prop="name">
              <el-input v-model="row.name" placeholder="请输入规则名称" clearable
                :style="{width: '200px'}"></el-input>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

      <el-row>
        <el-col :span="24">
                <el-form-item label="优先级" prop="priority">
              <el-input-number v-model="row.priority" placeholder="请输入优先级" clearable :controls="false" :step="1"
                :style="{width: '200px'}"></el-input-number>
            </el-form-item>
        </el-col>
            </el-row>

        <el-col :span="24">
            <el-form-item label="供应商" prop="supplier">
              
              <el-select v-model="row.supplierOperator"
                clearable
                placeholder="请选择"
                style="width: 100px;margin-right:10px;"
                >
                    <el-option
                          v-for="item in enums['Operator']"
                          :key="item.value"
                          :label="item.name"
                          :value="item.value">
                        </el-option>
              </el-select>

              <el-select ref="supplier"
                                v-model="row.supplier"
                                prop="supplier"
                                placeholder="请选择供应商"
                                clearable
                                filterable
                                multiple
                                :filter-method="listWmsSupplier"
                            >
                            <el-option
                                v-for="item in optionsOfWmsSupplier"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                            ></el-option>
                </el-select>

            </el-form-item>
        </el-col>
            </el-row>

            <el-row>

        <el-col :span="24">
            <el-form-item label="司机" prop="driverId">
                <el-select ref="driverId"
                                v-model="row.driverId"
                                prop="driverId"
                                placeholder="请选择司机"
                                clearable
                                filterable
                                :filter-method="listWmsDriver"
                            >
                            <el-option
                                v-for="item in optionsOfWmsDriver"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id"
                                  :disabled="item.status != 0"
                            ></el-option>
                </el-select>
            </el-form-item>
        </el-col>
            </el-row>
            <el-row>

        </el-row>

      </el-form>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getEnums, uploadFile } from "@/api/common"
import { editWmsCarrierRule } from "@/api/wms/wmsCarrierRule"

import { listWmsDriver } from "@/api/wms/wmsDriver"
import { listWmsSupplier } from "@/api/wms/wmsSupplier"


export default {
  inheritAttrs: false,
  components: { },
  props: [],
  data() {
    return {
      visible: false,
      row: {
            priority: null,
            name: null,
            supplier: null,
            supplierOperator: null,
            driverId: null,
            driverName: null,
      },
      rules: {
            priority: [
                { required: true, message: '请输入优先级!'},
            ],
            name: [
                { required: true, message: '请输入规则名称!'},
            ],
            driverId: [
                { required: true, message: '请输入司机!'},
            ],
      },
        enums: {},
        enumMaps: {},
        optionsOfWmsDriver: [],
        optionsOfWmsSupplier: [],

    }
  },
  computed: {},
  watch: {},
  created() {
    this.initEnums()
    this.listWmsDriver()
    this.listWmsSupplier()
  },
  mounted() {},
  methods: {
      initEnums() {
          let names = []
          names.push('Operator')
          const param = {
              names: names
          }
          getEnums(param).then(res=>{
              if(res.code===200) {
                this.enums = res.data.enums
                Object.keys(this.enums).forEach(key => {
                  let map = {}
                  this.enums[key].forEach(obj => map[obj.value] = obj.name)
                  this.enumMaps[key] = map
                })
              } else {
                  this.$message.error(res.message)
              }
          })
    },
    onOpen(row) {
        this.visible = true
        this.row = row
    },
    onClose() {
    },
    close() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    success(result) {
      this.$emit('success', result)
    },
    listWmsDriver(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsDriver(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsDriver = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    listWmsSupplier(val) {
        const param = {
            name: val,
            pageSize: 20,
        }
        listWmsSupplier(param).then(res => {
            if(res.code === 200) {
                this.optionsOfWmsSupplier = res.data
            } else {
                this.$message.error(res.message)
            }
        })
    },
    upload(param){
        const formData = new FormData()
        formData.append('file', param.file)
        uploadFile(formData).then(res => {
            if(res.code === 200){
                param.onSuccess(res.data.url)
                this.$message.success("上传成功")
            }else{
                this.$message.error(res.message)
            }
        }).catch(response => {
            console.log(response)
            this.$message.error('上传失败')
            param.onError()
        })
    },
    handleConfirm() {
      this.$refs['form'].validate(valid => {
        if (!valid) return;
        const param = this.row
        this.loading = true
        editWmsCarrierRule(param).then(res=>{
            this.loading = false
            if(res.code===200) {
                this.$message.success(res.message)
                this.success(res.data)
                this.close()
            } else {
                this.$message.error(res.message)
            }
        }).catch(err=>{
            this.loading = false
        })
      })
    },
  }
}

</script>
<style scoped>
.el-select {
    width: 200px;
}
.avatar {
    max-width: 200px;
    max-height: 200px
}
</style>
